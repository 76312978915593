import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { LoginRequestDto, LoginResponseDto, RegisterRequestDto, RegisterResponseDto, ResendEmailRequestDto } from "./auth.model";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    confirmEmailDetails: string[] | null = [];
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly httpClient: HttpClient) {}

    login(credentials: LoginRequestDto) {
        return this.httpClient.post<{ data: LoginResponseDto }>(`${environment.apiUrl}/customer/login`, credentials);
    }

    register(credentials: RegisterRequestDto) {
        return this.httpClient.post<{ data: RegisterResponseDto }>(`${environment.apiUrl}/customer/register`, credentials);
    }
    getUnverifiedEmail(data: ResendEmailRequestDto) {
        return this.httpClient.post<{}>(`${this.baseUrl}/customer/get-unverified-email`, data);
      }
      resendEmail(data: ResendEmailRequestDto) {
        return this.httpClient.post<{}>(`${this.baseUrl}/customer/resend-email`, data);
      }
}