export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: true,
  apiUrl: 'https://ecmsapi.judiciary.gm.gov.ng/api',
  courtTitle: 'HIGH COURT OF GOMBE',
  courtTitleSentence: 'Federal High Court of Nigeria',
  stateTitle: 'GOMBE STATE OF NIGERIA',
  stateTitleSentence: 'Gombe State Of Nigeria',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsapi.judiciary.gm.gov.ng/api',
  BASE_URL_II: 'https://ecmsapi.judiciary.gm.gov.ng/api',
  API_URL: '/',
  currentState: 'Gombe State',
  currentDivision: 'Gombe Judicial Division',
  PORTAL_URL: 'https://portal.judiciary.gm.gov.ng',
  court_bg_path: 'assets/img/gombe-ecms.png',
  court_logo_path: 'assets/img/gombe-ecms-2.png',
  paystack_public_key: 'pk_live_b499ff0710dd85ad36358f79c64f9161c380e89f',
  remitta_api_key: 'RkhDfDEzOTMwMjY1MzYyfDczOTdmMTIyZTJiYWFhNDUxYTUwNjJmOTkxYTBmOTlhOWJiOGY2OGRmMDVhNWE1MTYyZmI5MjRlYTllYTE1ZjcyYzQzMDRiNTJmMDhlMTdiNWY0ZGY1NDgyNTE0MjRlNTI5NWZiM2UxZDZmNzBjM2E5ZDQ0ZWFiOTg4ZGFiNTA4=',
  remitta_mode: 'live',
  interswitch_mechant_code: 'MX128354',
  // interswitch_pay_item: '103',
  interswitch_pay_item: 'Default_Payable_MX128354',
  interswitch_mode: 'LIVE',
  currentCourtInfoEmail: "info@gm.gov.ng"
};
